import React, {Component} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import BaseLoader from '@baseLoader';
import DashboardHead from './dashboardhead';
import Dashboardsubheader from './dashboardsubheader';
import DashboardBottom from './dashboardbottom';
import {fetchAppuser} from '@sidebarActions';

class DashboardMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_dashboard,
    };

    // All Binded Functions
  }

  componentDidMount() {
    let {session, user} = this.props;

    this.props.fetchAppuser(session, user.appuserid, callback => {
      this.setState({loading: false});
    });
  }

  render() {
    let {user} = this.props;

    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <div
            className="overflow-y-auto scrollbar-hide"
            style={{height: 'calc(100vh / var(--zoom) - 30px)'}}>
            {/* Divided the dashboard in the component */}
            <DashboardHead history={this.props.history} />
            <Dashboardsubheader history={this.props.history} />
            <DashboardBottom history={this.props.history} />
          </div>
        )}
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {
  fetchAppuser,
})(DashboardMain);
