import React, {Component} from 'react';
import {connect} from 'react-redux';
import _, {debounce} from 'lodash';
import LocaleStrings from '@language';
import {SearchIcon} from '@heroicons/react/solid';
import {classNames, isJSON} from '@commonFunction';
import {fetchmycontacts, searchmycontacts} from '@myContactsActions';
import BaseLoader from '@baseLoader';
import MyContactsListitems from './mycontacts-itemlist';
import toast, {Toaster} from 'react-hot-toast';
import ReportContactPopup from '../../search/components/reportcontactpopup';
import {contactmetaAddupdate} from '@myContactsActions';
import {parse} from '../../../../common/common-utils';
import PaginationBar from '../../../../common/paginationbar';

class Mycontacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_mycontacts,
      search: '',
      pageNumber: 1,
      perPage: '25',
      selectedUsers: [],
      allCheck: false,
      actionOption: false,
      btnXlsx: false,
      isBtnDisable: true,
      lastPageNumber: 1,
      selectedContact: undefined,
      openFlagPopup: false,
      numPages: 3,
    };

    this.throttleHandleChange = debounce(
      this.throttleHandleChange.bind(this),
      500,
    );

    // All Binded Functions
  }

  componentDidMount() {
    let {pageNumber, search, perPage} = this.state;
    let {session, user} = this.props;
    // fetching contacts
    this.props.fetchmycontacts(session, perPage, pageNumber, res => {
      this.calculateLastPage(res);
      this.setState({loading: false});
    });
  }

  throttleHandleChange(params) {
    const {session, perPage, pageNumber, searchKey} = params;
    this.props.searchmycontacts(
      session,
      perPage,
      pageNumber,
      searchKey,
      res => {
        if (res.success) {
          this.calculateLastPage(res);
        }
      },
    );
  }

  handlePagination = curPage => {
    let {search, perPage} = this.state;
    let {session, user} = this.props;

    let number = curPage;
    if (search !== '')
      this.props.searchmycontacts(session, perPage, number, search, res => {});
    else this.props.fetchmycontacts(session, perPage, number, res => {});
    this.setState({pageNumber: curPage});
  };

  calculateLastPage = response => {
    let perPage = this.state.perPage;

    let number =
      response.count % parseInt(perPage) > 0
        ? Math.floor(response.count / parseInt(perPage)) + 1
        : Math.floor(response.count / parseInt(perPage));

    this.setState({lastPageNumber: number});
  };

  // render list
  rendermycontactList() {
    let {mycontactsDataList} = this.props;
    let pageDataLength = mycontactsDataList.data.length;
    let {pageNumber} = this.state;
    var items = _.map(mycontactsDataList.data, (values, index) => (
      <MyContactsListitems
        key={values.contactid}
        keyindex={index}
        values={values}
        checkAndUncheckUsers={this.updateSelectedUser}
        selectedUsers={this.state.selectedUsers}
        // pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
        onUpgradePlan={() => this.props.history.push('/dashboard/upgradeplan')}
        onBuyAddon={() => this.props.history.push('/dashboard/creditaddons')}
        updateContactMeta={(val, action, type) => {
          if (action === 'upvote') {
            this.updateContactMeta(val, action, type, values.linkedinurl);
          } else {
            this.setState({
              openFlagPopup: true,
              selectedContact: {
                linkedinurl: values.linkedinurl,
                contact: val,
                contacttype: type,
              },
            });
          }
        }}
      />
    ));
    return items;
  }

  rendermycontactList1Row() {
    let {mycontactsDataList} = this.props;
    let pageDataLength = mycontactsDataList.data.length;
    let {pageNumber} = this.state;
    var items = _.map(mycontactsDataList.data.splice(1), (values, index) => (
      <MyContactsListitems
        key={`${values.contactid}-fake`}
        keyindex={index}
        values={values}
        checkAndUncheckUsers={this.updateSelectedUser}
        selectedUsers={this.state.selectedUsers}
        // pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
        onUpgradePlan={() => this.props.history.push('/dashboard/upgradeplan')}
        onBuyAddon={() => this.props.history.push('/dashboard/creditaddons')}
        updateContactMeta={(val, action, type) => {
          if (action === 'upvote') {
            this.updateContactMeta(val, action, type, values.linkedinurl);
          } else {
            this.setState({
              openFlagPopup: true,
              selectedContact: {
                linkedinurl: values.linkedinurl,
                contact: val,
                contacttype: type,
              },
            });
          }
        }}
      />
    ));
    return items;
  }
  // handle search
  handleSearch = event => {
    let {session, user} = this.props;
    let {pageNumber, lastPageNumber, search, perPage} = this.state;
    var searchKey = event.target.value;
    this.setState({search: searchKey});

    if (searchKey !== '') {
      this.throttleHandleChange({session, perPage, pageNumber, searchKey});
    } else {
      this.props.fetchmycontacts(session, perPage, pageNumber, res => {
        if (res.success) {
          this.calculateLastPage(res);
        }
      });
    }
  };
  // fetching according to limit i.e per page
  _fetchContactsperpage(e, comesFrom) {
    let {user, session} = this.props;
    let {pageNumber, lastPageNumber, search, perPage} = this.state;
    let perPageCount = 100;
    if (comesFrom == '25') {
      perPageCount = 25;
    } else if (comesFrom == '50') {
      perPageCount = 50;
    }

    if (search !== '') {
      this.props.searchmycontacts(
        session,
        perPageCount,
        pageNumber,
        search,
        res => {
          if (res.success) {
            this.calculateLastPage(res);
          }
        },
      );
    } else {
      this.props.fetchmycontacts(session, perPageCount, pageNumber, res => {
        if (res.success) {
          this.calculateLastPage(res);
        }
      });
    }
    this.setState({perPage: comesFrom});
  }

  // This function is used to show or hide action option and check or uncheck master check field
  checkMatchArray = selectedUsers => {
    setTimeout(() => {
      var mycontactsDataList = this.props.mycontactsDataList.data;

      var newArray = [];
      _.map(mycontactsDataList, contactUser => {
        var newSelectedUsers = _.filter(selectedUsers, function (selusers) {
          return selusers.contactid === contactUser.contactid;
        });

        if (newSelectedUsers && newSelectedUsers.length > 0) {
          // newArray = [...newArray, newSelectedUsers];
          newArray.push(newSelectedUsers[0]);
        }
      });

      // Check for current page selection and according to show or hide action option for bulk email send
      if (newArray.length > 0) {
        this.setState({actionOption: true});
      } else {
        this.setState({actionOption: false});
      }

      // Check for all user selected or not according to that check or uncheck master check option
      if (
        newArray.length > 0 &&
        newArray.length === mycontactsDataList.length
      ) {
        this.setState({allCheck: true});
        this.setState({isBtnDisable: false});
      } else {
        this.setState({allCheck: false});
      }
    }, 100);
  };

  selectAllUsers = () => {
    var state = this.state.allCheck;
    var selectedUsers = this.state.selectedUsers;
    var allUsers = this.props.mycontactsDataList.data;
    var newSelectUser = [];
    var newSelectedUsers = [];

    if (state == false) {
      _.map(allUsers, user => {
        var checkUserExitOrNot = _.filter(selectedUsers, function (selusers) {
          return selusers.contactid === user.contactid;
        });

        //Check if user is not exist in selected user that add
        if (checkUserExitOrNot.length == 0) {
          newSelectUser = {...user, checked: true};
          selectedUsers = [...selectedUsers, newSelectUser];
        }

        this.checkMatchArray(selectedUsers);
        this.setState({selectedUsers});
        this.setState({isBtnDisable: false});
      });
    } else {
      var leftUsers = selectedUsers;
      _.map(allUsers, user => {
        newSelectedUsers = _.filter(leftUsers, function (selusers) {
          return selusers.contactid !== user.contactid;
        });
        leftUsers = newSelectedUsers;

        this.checkMatchArray(leftUsers);
        this.setState({selectedUsers: leftUsers});
        this.setState({isBtnDisable: false});
      });
    }
  };

  // Selecting one one user
  updateSelectedUser = user => {
    var selectedUsers = this.state.selectedUsers;
    var index = _.findIndex(selectedUsers, function (curuser) {
      return curuser.contactid === user.contactid;
    });

    if (index >= 0) {
      var newSelectedUsers = _.filter(selectedUsers, function (selusers) {
        return selusers.contactid !== user.contactid;
      });

      this.checkMatchArray(newSelectedUsers);
      this.setState({selectedUsers: newSelectedUsers});
    } else {
      if (!user.deleted) {
        var newSelectUser = {...user, checked: true};
        selectedUsers = [...selectedUsers, newSelectUser];
      }

      this.checkMatchArray(selectedUsers);
      this.setState({selectedUsers});
      this.setState({isBtnDisable: false});
    }
  };
  // exporting selected data
  exportBills = (data, ShowLabel) => {
    // let arr = [];
    // arr.push(data.userdata); //

    if (data.length > 0) {
      this.setState({btnXlsx: true});
      let JSONData = {};
      JSONData['mycontacts'] = data;
      this.JSONToCSVConvertor(JSONData, ShowLabel);
      this.setState({btnXlsx: false});
    } else {
      toast.error('Please Select Contacts to Export');
    }
  };
  // called from exportBills for downloading report
  JSONToCSVConvertor = (JSONData, ShowLabel) => {
    this.setState({btnXlsx: true});
    var XLSX = require('xlsx');
    let wb = XLSX.utils.book_new();

    let arrjsonn = [];
    let sessionToken = this.props.session.sessionToken;
    _.forEach(JSONData.mycontacts, function (value) {
      // console.log("value", value);
      let emailArr = [];
      let parsedEmail = parse(value.emails, sessionToken);
      let emailsjson = isJSON(parsedEmail);
      if (emailsjson) {
        emailArr = JSON.parse(parsedEmail);
      }
      let emailArr2 = [];
      emailArr.map((val, key) => {
        let obj = val.address;
        emailArr2.push(obj);
      });

      let personalemailcomaseperated = '';
      let businessemailcomaseperated = '';

      let businessemailArr = [];
      let parsedBusEmails = parse(value.business_emails, sessionToken);
      if (parsedBusEmails != '' && parsedBusEmails != null) {
        businessemailArr.push(parsedBusEmails);
      }

      if (emailArr2.length > 0) {
        personalemailcomaseperated = emailArr2.slice(0, 3).join(',');
      }

      if (businessemailArr.length > 0) {
        businessemailcomaseperated = businessemailArr.slice(0, 3).join(',');
      }

      let phoneArr = [];
      let parsedPhone = parse(value.phone_numbers, sessionToken);
      let phonejson = isJSON(parsedPhone);
      if (phonejson) {
        phoneArr = JSON.parse(parsedPhone);
      }

      let phonenumbersomaseperated = '';
      if (phoneArr.length > 0) {
        phoneArr = phoneArr.filter(num => typeof num === 'string');
        phonenumbersomaseperated = phoneArr.slice(0, 3).join(',');
      }

      var obj = {
        full_name: value.name,
        linkedin_url: value.linkedinurl,
        secondary_emails: personalemailcomaseperated,
        work_emails: businessemailcomaseperated,
        cell_numbers: phonenumbersomaseperated,
      };

      arrjsonn.push(obj);
    });

    let JSONDataOBJ = {
      mycontacts: arrjsonn,
    };
    _.map(JSONDataOBJ, (item, index) => {
      /* Create a worksheet */
      var ws = XLSX.utils.json_to_sheet(item);
      // // var ws1 = XLSX.utils.json_to_sheet(JSONData['F2(149426)']);

      var sheetName = index.replace(/[\/\\:\[\]*?]/g, '_'); // Sheet name cannot contain ': \ / ? * [ ]' so replacing with '_' and also can not contain more than 31 chars
      if (sheetName.length > 30) {
        sheetName = sheetName.substr(0, 28) + '...';
      }

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      // XLSX.utils.book_append_sheet(wb, ws1, "F2(149426)");
    });
    XLSX.writeFile(wb, 'mycontacts.xlsx');
    this.setState({btnXlsx: false});
  };

  updateContactMeta = (val, action, type, linkedinurl, downvotedata) => {
    let obj = {};
    obj.linkedin_url = linkedinurl;
    obj.info = val;
    obj.action = action;
    obj.type = type;

    if (action === 'downvote') {
      obj.downvotereason = downvotedata.downvotereason;
      obj.reasonnote = downvotedata.reasonnote;
    }

    this.props.contactmetaAddupdate(this.props.session, obj, callback => {
      toast.success('Thanks! Your feedback has been submitted.');
    });
  };

  render() {
    let {pageNumber, lastPageNumber, perPage, selectedUsers, search} =
      this.state;
    let {mycontactsDataList} = this.props;
    const noData =
      !mycontactsDataList ||
      !mycontactsDataList.data ||
      mycontactsDataList.data.length === 0;

    return (
      <>
        <div>
          {this.state.loading ? (
            <>
              <BaseLoader data={this.state.loaderDesc} />
            </>
          ) : (
            <>
              <div className="flex flex-col shadow ring-1 ring-black ring-opacity-5 md:rounded-lg relative">
                <div className="overflow-x-hidden" style={{marginTop: '76px'}}>
                  <div
                    className="inline-block min-w-full py-2 align-middle ml-1"
                    style={{height: 'calc(100vh / var(--zoom) - 310px)'}}>
                    {noData ? (
                      <div className="center-item my-24 lg:my-48 text-lg text-ternary">
                        {LocaleStrings.no_data_found}
                      </div>
                    ) : (
                      <div className="overflow-y-auto -my-2 relative">
                        <table className="min-w-full table-fixed divide-y divide-gray-300">
                          <thead className="bg-white table w-full">
                            <tr>
                              <th
                                scope="col"
                                className="w-12 px-8 relative sticky top-0">
                                <input
                                  type="checkbox"
                                  className="absolute left-4 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  style={{bottom: '16px'}}
                                  onChange={this.selectAllUsers}
                                  checked={this.state.allCheck}
                                />
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pr-3 text-left text-sm font-medium text-gray500 sticky top-0">
                                {LocaleStrings.mycontacts_th1}
                              </th>

                              <th
                                scope="col"
                                className="px-5 py-3.5 text-left text-sm font-medium text-gray500 sticky top-0"
                                style={{width: '20%'}}>
                                {LocaleStrings.mycontacts_th5}
                              </th>

                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-medium text-gray500 sticky top-0"
                                style={{width: '30%'}}>
                                {LocaleStrings.mycontacts_th4}
                              </th>
                              <th
                                scope="col"
                                className="px-5 py-3.5 text-left text-sm font-medium text-gray500 sticky top-0"
                                style={{width: '30%'}}>
                                {LocaleStrings.mycontacts_th3}
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            className="divide-y divide-gray-200 bg-white block w-full overflow-auto"
                            style={{
                              height: 'calc(100vh / var(--zoom) - 360px)',
                            }}>
                            {this.rendermycontactList()}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
                <nav className="flex items-center justify-between border-t border-gray-200 px-4 py-2 bg-white rounded-b-lg">
                  <PaginationBar
                    hidden={noData}
                    curPage={this.state.pageNumber}
                    numPages={this.state.lastPageNumber}
                    maxPage={this.state.perPage}
                    onPageChange={curPage => {
                      this.handlePagination(curPage);
                    }}
                    onNavigateFirst={() => {
                      this.handlePagination(1);
                    }}
                    onNavigateLast={() => {
                      this.handlePagination(this.state.lastPageNumber);
                    }}
                  />

                  {!noData ? (
                    <div className="justify-end">
                      <div className="mt-4 md:mt-0 flex end">
                        <div className="">
                          <span className="isolate inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              onClick={e => this._fetchContactsperpage(e, '25')}
                              className={classNames(
                                perPage == '25'
                                  ? 'bg-gray-50  border-indigo-500 '
                                  : 'bg-white',
                                'relative inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50  focus:outline-none ',
                              )}>
                              25
                            </button>
                            <button
                              type="button"
                              onClick={e => this._fetchContactsperpage(e, '50')}
                              className={classNames(
                                perPage == '50'
                                  ? 'bg-gray-50  border-indigo-500'
                                  : 'bg-white',
                                'relative -ml-px inline-flex items-center border border-gray-300  px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ',
                              )}>
                              50
                            </button>
                            <button
                              type="button"
                              onClick={e =>
                                this._fetchContactsperpage(e, '100')
                              }
                              className={classNames(
                                perPage == '100'
                                  ? 'bg-gray-50  border-indigo-500'
                                  : 'bg-white',
                                'relative -ml-px inline-flex items-center rounded-r-md border border-gray-300  px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50',
                              )}>
                              100
                            </button>
                          </span>
                        </div>
                        <div className="mt-2 ml-2 text-sm font-medium text-gray500">
                          per page
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {noData ? <div className="h-10" /> : null}
                </nav>
                <div className="absolute inline-block min-w-full align-middle bg-white rounded-t-lg border-b py-4">
                  <div className="justify-between flex flex-row px-4">
                    <div class="bg-white flex start w-1/2">
                      <header class="px-5 w-full">
                        <div className="justify-between flex flex-row w-full">
                          <div className="relative text-ternary focus-within:text-secondary w-full">
                            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-3">
                              <SearchIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </div>
                            <input
                              id="search-field"
                              className="block shadow border-gray-300 w-full h-full pl-8 pr-3 py-3 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                              placeholder={'Search'}
                              type="search"
                              name="search"
                              value={this.state.search}
                              onChange={this.handleSearch}
                            />
                          </div>
                        </div>
                      </header>
                    </div>
                    <div className="flex end">
                      <div className="flex items-center justify-center">
                        <button
                          type="button"
                          onClick={() => this.exportBills(selectedUsers)}
                          disabled={this.state.isBtnDisable}
                          className={classNames(
                            this.state.isBtnDisable
                              ? 'btn-disabled'
                              : 'btn-primary',
                            'ml-4',
                          )}>
                          <svg
                            className={classNames(
                              this.state.btnXlsx ? '' : 'sr-only',
                              'animate-spin-medium h-5 w-5 rounded-full mx-2',
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          <img
                            src="../../images/exportexcel.png"
                            className="pr-2 mt-1"
                          />
                          {LocaleStrings.mycontacts_headerbtn}
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <table className="min-w-full table-fixed divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th scope="col" className="w-12 px-2">
                              <input
                                type="checkbox"
                                className="absolute left-5 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                style={{bottom: '21px'}}
                                onChange={this.selectAllUsers}
                                checked={this.state.allCheck}
                              />
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pr-3 pl-5 text-left text-sm font-semibold text-gray-500"
                              style={{width: '18%'}}>
                              {LocaleStrings.mycontacts_th1}
                            </th>
                            
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500">
                              {LocaleStrings.mycontacts_th5}
                            </th>

                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                              style={{width: '34%'}}>
                              {LocaleStrings.mycontacts_th4}
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                              style={{width: '26%'}}>
                              {LocaleStrings.mycontacts_th3}
                            </th>
                          </tr>
                        </thead>
                      </table> */}
                </div>
              </div>
            </>
          )}
        </div>
        <ReportContactPopup
          open={this.state.openFlagPopup}
          {...this.state.selectedContact}
          onClose={() => {
            this.setState({openFlagPopup: false});
          }}
          onSubmit={data => {
            this.updateContactMeta(
              this.state.selectedContact.contact,
              'downvote',
              this.state.selectedContact.contacttype,
              this.state.selectedContact.linkedinurl,
              data,
            );
            this.setState({openFlagPopup: false, selectedContact: undefined});
          }}
        />
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  mycontactsDataList: state.mycontactsDataList,
});

export default connect(mapStateToProps, {
  fetchmycontacts,
  contactmetaAddupdate,
  searchmycontacts,
})(Mycontacts);
