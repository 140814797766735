import React, {Component, useLayoutEffect} from 'react';
import {connect} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import {HashRouter, BrowserRouter, Route, Switch} from 'react-router-dom';
import LocaleStrings from '@language';
// import { createBrowserHistory } from "history";
import thunk from 'redux-thunk';
import {loadingBarMiddleware} from 'react-redux-loading-bar';
import {errorBarMiddleware} from './common/errorbar/index';
import Login from './components/login/index';
import Register from './components/register/index';
import ForgotPassword from './components/forgotpassword/index';
import ResetPassword from './components/resetpassword/index';
import reducers from './reducers';
import Main from './components/dashboard/sidebar/components/main';
import Tellusmore from './components/dashboard/sidebar/components/tellusmore';
import VerifyEmail from './components/verifyemail/index';
import Emailverification from './components/verifyemail/emailverification';
import AppuserInviteAccept from './components/appuseracceptinvite/index';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {getDeviceSize} from './actions';

import {loadState, saveState} from './localStorage';

var persistedState = loadState();

var createStoreWithMiddleware = applyMiddleware(
  thunk,
  loadingBarMiddleware(),
  errorBarMiddleware(),
)(createStore);

export var store = createStoreWithMiddleware(reducers, persistedState);

store.subscribe(() => {
  saveState(store.getState());
});

const App = props => {
  // constructor(props) {
  //   super(props);
  //   if (!this.props.isLoggedIn) {
  //   }

  //   if (this.props.language === "ar") {
  //     LocaleStrings.setLanguage("ar");
  //   } else {
  //     LocaleStrings.setLanguage("en");
  //   }
  // }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const updateSize = () => {
    // GETTING DYNAMIC HEIGHT AND WIDTH
    props.getDeviceSize();
  };

  return (
    <div style={{height: 'calc(100vh / var(--zoom))'}}>
      <TransitionGroup>
        <CSSTransition
          transitionName="example"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
          timeout={300}>
          <HashRouter>
            <Switch>
              {/*  MAIN ROUETES */}
              {/*  All Routes used  are for before login screen except "/dashboard" */}
              {/* /dashboard Main hass all the routes after login succeess path for main ./components/dashboard/sidebar/components/main*/}
              <Route
                path="/acceptinvite"
                component={props => <AppuserInviteAccept {...props} key="9" />}
              />
              <Route
                path="/emailverification"
                component={props => <Emailverification {...props} key="8" />}
              />
              <Route
                path="/verifyemail"
                component={props => <VerifyEmail {...props} key="7" />}
              />
              <Route
                path="/resetpassword"
                component={props => <ResetPassword {...props} key="5" />}
              />
              <Route
                path="/forgotpassword"
                component={props => <ForgotPassword {...props} key="3" />}
              />
              <Route
                path="/register"
                component={props => <Register {...props} key="2" />}
              />
              <Route
                path="/dashboard"
                component={props => <Main {...props} key="4" />}
              />
              <Route
                path="/tellusmore"
                component={props => <Tellusmore {...props} key="6" />}
              />
              <Route
                path="/"
                component={props => <Login {...props} key="1" />}
              />
            </Switch>
          </HashRouter>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
  // }
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    language: state.language,
  };
}

// export default connect(mapStateToProps)(App);

export default connect(mapStateToProps, {getDeviceSize})(App);
