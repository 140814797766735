import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/solid';
import {useEffect, useState} from 'react';
import {classNames} from '@commonFunction';
import LocaleStrings from '@language';

// const maxRowPerPage = 10;

export default function PaginationBar(props) {
  const [pages, setPages] = useState([]);
  const [curPage, setCurPage] = useState(parseInt(props.curPage));
  const [paginationIndex, setPaginationIndex] = useState(1);
  const [maxRowPerPage, setMaxRowPerPage] = useState(props.maxPage);

  // let pageCounts = Math.ceil(parseInt(props.numPages) / maxRowPerPage);
  // const diff = parseInt(props.numPages) - pageCounts * maxRowPerPage;
  // if (diff > 0) {
  //   pageCounts = pageCounts + 1;
  // }

  // const [maxPagination] = useState(pageCounts);

  useEffect(() => {
    const startIndex = (paginationIndex - 1) * maxRowPerPage;
    let endIndex = (paginationIndex - 1) * maxRowPerPage + maxRowPerPage;

    if (endIndex > props.numPages) {
      endIndex = props.numPages;
    }

    var arr = [];
    for (let i = startIndex; i < endIndex; i++) {
      arr.push({page: `${i + 1}`, type: 'number'});
    }
    setPages(arr);
  }, [paginationIndex]);

  useEffect(() => {
    props.onPageChange(curPage);
  }, [curPage]);

  useEffect(() => {
    setCurPage(props.curPage);
  }, [props.curPage]);

  useEffect(() => {
    setMaxRowPerPage(props.maxPage);
  }, [props.maxPage]);

  return (
    <div
      className={classNames(
        'flex items-center justify-between bg-white px-2 py-2',
        props.hidden ? 'opacity-0' : 'opacity-100',
      )}>
      <div className="flex flex-1 items-center justify-between">
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination">
            <div
              onClick={() => {
                props.onNavigateFirst();
              }}
              className={classNames(
                'relative inline-flex items-center hover:cursor-pointer rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
              )}>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            {pages.map(({page, type}) => {
              const thePage = type === 'number' ? parseInt(page) : page;
              return (
                <div
                  key={page}
                  onClick={() => {
                    if (type === 'number' && !props.disabled) {
                      setCurPage(thePage);
                    }
                  }}
                  className={classNames(
                    'relative z-10 inline-flex items-center px-4 py-2 text-sm  hover:cursor-pointer',
                    thePage === curPage
                      ? 'bg-primary text-white z-20 outline outline-2 outline-offset-2'
                      : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
                  )}>
                  {page}
                </div>
              );
            })}
            <div
              className={classNames(
                'relative inline-flex hover:cursor-pointer items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
              )}
              onClick={() => {
                props.onNavigateLast();
              }}>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
