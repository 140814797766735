import axios from 'axios';
import {APP_API_KEY, INSTANCE_URL, LOGIN} from '@constant';
import {
  postRequest,
  patchRequest,
  postRequestAccess,
  getRequest,
  postRequestnew,
} from '@networkCall';
// import { getHeaders } from "../common/common-utils.js";
import LocaleStrings from '@language';
import _ from 'lodash';

export var ACTION_LOGOUT = 'ACTION_LOGOUT';
export var SESSION_TOKEN = 'SESSION_TOKEN';
export var AVATAR = 'AVATAR';
export var CLIENT_ID = 'CLIENT_ID';
export var APPUSERID = 'APPUSERID';
export var USER_EMAIL = 'USER_EMAIL';
export var IS_ACTIVE = 'IS_ACTIVE';
export var IS_DELETED = 'IS_DELETED';
export var IS_VERIFIED = 'IS_VERIFIED';
export var USER_NAME = 'USER_NAME';
export var ROLE = 'ROLE';
export var USERCREDIT_BY_CLIENTID = 'USERCREDIT_BY_CLIENTID';
export var USERSUBSCRIPTION_BY_CLIENTID = 'USERSUBSCRIPTION_BY_CLIENTID';
export var DEVICE_SIZE = 'DEVICE_SIZE';
export var IS_FORGOT_PASSWORD_OPEN = 'IS_FORGOT_PASSWORD_OPEN';
export var LOCALE_LANGUAGE = 'LOCALE_LANGUAGE';
export var SET_CURRENCY = 'SET_CURRENCY';
export var IS_VERFICATION_MODAL_OPEN = 'IS_VERFICATION_MODAL_OPEN';
export var STRIPE_CUST_ID = 'STRIPE_CUST_ID';

// Patch users db table for preferred
export function localeLanguage(lang, session) {
  return dispatch => {
    dispatch({type: LOCALE_LANGUAGE, payload: 'en'});
  };
}

export function setCurrency(session, currency, callback) {
  return dispatch => {
    dispatch({type: SET_CURRENCY, payload: 'usd'});
  };
}
export function getDeviceSize() {
  // GETTING DYNAMIC HEIGHT AND WIDTH
  let screen = window.screen;
  var isMobile = window.innerWidth > 0 ? false : true;
  let width = 1440;
  let height = 815;

  if (isMobile === false) {
    width = window.innerWidth;
    height = window.innerHeight;
  } else {
    width = screen.width;
    height = screen.height;
  }
  let size = {width: width, height: height};
  return {type: DEVICE_SIZE, payload: size};
}

// Forgot password Modal open or not
export function isVerficationModalOpen(isOpen) {
  return {type: IS_VERFICATION_MODAL_OPEN, payload: isOpen};
}

// Forgot password Modal open or not
export function isForgotPasswordOpen(isOpen) {
  return {type: IS_FORGOT_PASSWORD_OPEN, payload: isOpen};
}

export function resetStore() {
  return dispatch => {
    dispatch({type: 'RESET'});
    dispatch({type: SESSION_TOKEN, payload: {}});
  };
}

export function mainLogin(values, session, callback) {
  //This function is used on Login screen only
  var config = {
    headers: {
      contentType: 'application/json; charset=utf-8',
      'X-ApiFlo-Api-Key': APP_API_KEY,
    },
  };

  if (values.comesFrom == 'loginpage') {
    var data = {
      email: values.email,
      password: values.password,
    };
  } else {
    var data = {
      session_token: values.session_token,
    };
  }

  var url = `${INSTANCE_URL}/api/v2/user/session`;
  var response = axios.post(url, data, config);
  return dispatch => {
    response
      .then(({data}) => {
        if (data.error) {
          if (data.error == 802) {
            // console.log("data", data.user.appuserid);
            dispatch({type: CLIENT_ID, payload: data.user.clientid});
            dispatch({type: USER_NAME, payload: data.user.name});
            dispatch({type: APPUSERID, payload: data.user.appuserid});
            dispatch({type: USER_EMAIL, payload: data.user.email});
            callback({success: 2, data: 'Unverified'});
          } else if (data.error == 801) {
            callback({success: 3, data: 'Deleted user'});
          } else if (data.error == 800) {
            callback({success: 4, data: 'Disabled user'});
          } else if (data.error == 803) {
            callback({success: 5, data: 'Invitation not accepted'});
          }
        } else {
          if (
            data &&
            data.isactive == 1 &&
            data.isverified == 1 &&
            data.isdeleted == 0
          ) {
            dispatch({type: SESSION_TOKEN, payload: data});
            dispatch({type: AVATAR, payload: data.avatar});
            dispatch({type: CLIENT_ID, payload: data.clientid});
            dispatch({type: APPUSERID, payload: data.appuserid});
            dispatch({type: USER_EMAIL, payload: data.email});
            dispatch({type: IS_ACTIVE, payload: data.isactive});
            dispatch({type: IS_DELETED, payload: data.isdeleted});
            dispatch({type: USER_NAME, payload: data.name});
            dispatch({type: ROLE, payload: data.role});
            dispatch({
              type: USERCREDIT_BY_CLIENTID,
              payload: data.client_by_clientid?.usercredit_by_clientid,
            });
            dispatch({
              type: USERSUBSCRIPTION_BY_CLIENTID,
              payload: data.client_by_clientid?.usersubscription_by_clientid,
            });
            dispatch({
              type: STRIPE_CUST_ID,
              payload: data.client_by_clientid?.stripe_custid,
            });

            dispatch({type: LOGIN, payload: true});
            callback({success: 1, data: data});
          }
          // else if (data.isverified == 0) {
          // dispatch({ type: SESSION_TOKEN, payload: data });
          // dispatch({ type: AVATAR, payload: data.avatar });
          // dispatch({ type: CLIENT_ID, payload: data.clientid });
          // dispatch({ type: APPUSERID, payload: data.appuserid });
          // dispatch({ type: USER_EMAIL, payload: data.email });
          // dispatch({ type: IS_ACTIVE, payload: data.isactive });
          // dispatch({ type: IS_DELETED, payload: data.isdeleted });
          // dispatch({ type: USER_NAME, payload: data.name });
          // dispatch({ type: ROLE, payload: data.role });
          // dispatch({
          //   type: USERCREDIT_BY_CLIENTID,
          //   payload: data.client_by_clientid?.usercredit_by_clientid,
          // });
          // dispatch({
          //   type: USERSUBSCRIPTION_BY_CLIENTID,
          //   payload: data.client_by_clientid?.usersubscription_by_clientid,
          // });
          // dispatch({
          //   type: STRIPE_CUST_ID,
          //   payload: data.client_by_clientid?.stripe_custid,
          // });
          // dispatch({ type: LOGIN, payload: true });
          //   callback({ success: 2, data: "Unverified" });
          // } else if (data.isdeleted == 1) {
          //   callback({ success: 3, data: "Deleted user" });
          // } else if (data.isactive == 0) {
          //   callback({ success: 4, data: "Disabled user" });
          // }
        }
      })
      .catch(error => {
        callback({success: 0, message: error});
      });
  };
}

export function fetchsessionData(values, session, callback) {
  //This function is used on Login screen only
  // console.log("values", values);
  var config = {
    headers: {
      contentType: 'application/json; charset=utf-8',
      'X-ApiFlo-Api-Key': APP_API_KEY,
      'X-ApiFlo-Session-Token': values.session_token,
    },
  };
  var url = `${INSTANCE_URL}/api/v2/user/session?session_token=${values.session_token}`;
  var response = axios.get(url, null, config);
  return dispatch => {
    response
      .then(({data}) => {
        // console.log("data", data);
        dispatch({type: SESSION_TOKEN, payload: data});
        dispatch({type: AVATAR, payload: data.avatar});
        dispatch({type: CLIENT_ID, payload: data.clientid});
        dispatch({type: APPUSERID, payload: data.appuserid});
        dispatch({type: USER_EMAIL, payload: data.email});
        dispatch({type: IS_ACTIVE, payload: data.isactive});
        dispatch({type: IS_DELETED, payload: data.isdeleted});
        dispatch({type: USER_NAME, payload: data.name});
        dispatch({type: ROLE, payload: data.role});
        dispatch({
          type: USERCREDIT_BY_CLIENTID,
          payload: data.client_by_clientid?.usercredit_by_clientid,
        });
        dispatch({
          type: USERSUBSCRIPTION_BY_CLIENTID,
          payload: data.client_by_clientid?.usersubscription_by_clientid,
        });
        dispatch({
          type: STRIPE_CUST_ID,
          payload: data.client_by_clientid?.stripe_custid,
        });
        dispatch({type: LOGIN, payload: true});
        callback({success: 1, data: data});
      })
      .catch(error => {
        callback({success: 0, message: error});
      });
  };
}

//LOGOUT
export function logout(session) {
  return dispatch => {
    dispatch({type: ACTION_LOGOUT, payload: false});
    dispatch({type: 'RESET'});
  };
}

export function validateSocialId(socialID, callback) {
  // var url = `${INSTANCE_URL}/api/v2/appscript?type=verifysocialid`;
  // var params = { uniqueid: socialID };
  // return (dispatch) => {
  //   postRequestAccess(
  //     url,
  //     params,
  //     null,
  //     dispatch,
  //     (response) => {
  //       if (response.result === "success" && response.email) {
  //         callback({
  //           success: 1,
  //           email: response.email,
  //         });
  //       } else {
  //         callback({
  //           success: 0,
  //         });
  //       }
  //     },
  //     (error) => {
  //       //callback({success: 0, error: error});
  //     }
  //   );
  // };
}

export function socialSignupAvailable(email, callback) {
  // var url = `${INSTANCE_URL}/api/v2/appscript?type=socialsignupavailable`;
  // var params = { email: email };
  // return (dispatch) => {
  //   postRequestAccess(
  //     url,
  //     params,
  //     null,
  //     dispatch,
  //     (response) => {
  //       if (response.result === "success" && response.available === 1) {
  //         callback({
  //           success: 1,
  //         });
  //       } else {
  //         callback({
  //           success: 0,
  //         });
  //       }
  //     },
  //     (error) => {
  //       //callback({success: 0, error: error});
  //       // Note - If available = 1 means the user already registered as a Vendor, Show error
  //     }
  //   );
  // };
}

// REGISTER
export function resgisterUser(values, callback) {
  //This function is used on Login screen only
  var config = {
    headers: {
      contentType: 'application/json; charset=utf-8',
      'X-ApiFlo-Api-Key': APP_API_KEY,
    },
  };

  var data = {
    email: values.email,
    name: values.name,
    password: values.password,
  };
  if (values.k_current_visit_source) {
    data.k_current_visit_source = values.k_current_visit_source;
  }
  if (values.k_current_visit_source_time) {
    data.k_current_visit_source_time = values.k_current_visit_source_time;
  }
  if (values.k_referral_data) {
    data.k_referral_data = values.k_referral_data;
  }

  let body = {resource: [data]};

  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/client`;
  var response = axios.post(url, body, config);
  return dispatch => {
    response
      .then(({data}) => {
        if (data.resource && data.resource.length > 0) {
          let userdata = data.resource[0]?.clientdata.user;
          // dispatch({ type: SESSION_TOKEN, payload: userdata });
          // dispatch({ type: AVATAR, payload: userdata.avatar });
          dispatch({type: CLIENT_ID, payload: userdata.clientid});
          dispatch({type: USER_NAME, payload: userdata.name});
          dispatch({type: APPUSERID, payload: userdata.appuserid});
          dispatch({type: USER_EMAIL, payload: userdata.email});
          // dispatch({ type: IS_ACTIVE, payload: userdata.isactive });
          // dispatch({ type: IS_DELETED, payload: userdata.isdeleted });
          // dispatch({ type: USER_NAME, payload: userdata.name });
          // dispatch({ type: ROLE, payload: userdata.role });
          // dispatch({ type: LOGIN, payload: true });
          callback({success: 1, data: userdata});
        } else {
          callback({success: 0, data: data});
        }
      })
      .catch(error => {
        callback({success: 0, message: error});
      });
  };
}

export function sendresetpasswordLink(values, callback) {
  //This function is used on Login screen only
  var config = {
    headers: {
      contentType: 'application/json; charset=utf-8',
      'X-ApiFlo-Api-Key': APP_API_KEY,
    },
  };
  var body = {
    email: values.email,
  };

  var url = `${INSTANCE_URL}/api/v2/user/password?reset=true`;
  var response = axios.post(url, body, config);
  return dispatch => {
    response
      .then(({data}) => {
        callback({success: 1, data: data});
      })
      .catch(error => {
        callback({success: 0, message: error});
      });
  };
}

// REGISTER
export function resetpassword(values, callback) {
  //This function is used on Login screen only
  var config = {
    headers: {
      contentType: 'application/json; charset=utf-8',
      'X-ApiFlo-Api-Key': APP_API_KEY,
    },
  };

  var body = {
    code: values.code,
    email: values.email,
    new_password: values.password,
  };

  var url = `${INSTANCE_URL}/api/v2/user/password`;
  var response = axios.post(url, body, config);
  return dispatch => {
    response
      .then(({data}) => {
        callback({success: 1, data: data});
      })
      .catch(error => {
        callback({success: 0, message: error});
      });
  };
}

export function updateTellusmoreAppuser(session, obj, callback) {
  let url = `${INSTANCE_URL}/api/v2/thomsondata/_table/appuser`;
  // console.log('newAddArrdata',newAddArrdata)
  let body = {resource: obj};

  // let body = {resource: [obj] };

  return dispatch => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}

export function validateEmailForAppUser(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/checkemailexists`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}

export function validateDomain(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/validatedomain`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}

export function socialRegisterorLogin(values, callback) {
  //This function is used on Login screen only
  var config = {
    headers: {
      contentType: 'application/json; charset=utf-8',
      'X-ApiFlo-Api-Key': APP_API_KEY,
    },
  };

  // var data = {
  //   email: values.email,
  //   name: values.name,
  //   password: values.password,
  // };
  let body = {resource: [values]};

  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/client`;
  var response = axios.post(url, body, config);
  return dispatch => {
    response
      .then(({data}) => {
        // console.log("login", data);
        if (
          data.resource &&
          data.resource.length > 0 &&
          data.resource[0].afid
        ) {
          // console.log('login2',data);
          let data1 = data.resource[0];
          dispatch({type: SESSION_TOKEN, payload: data1});
          dispatch({type: AVATAR, payload: data1.avatar});
          dispatch({type: CLIENT_ID, payload: data1.clientid});
          dispatch({type: APPUSERID, payload: data1.appuserid});
          dispatch({type: USER_EMAIL, payload: data1.email});
          dispatch({type: IS_ACTIVE, payload: data1.isactive});
          dispatch({type: IS_DELETED, payload: data1.isdeleted});
          dispatch({type: IS_VERIFIED, payload: data1.isverified});
          dispatch({type: USER_NAME, payload: data1.name});
          dispatch({type: ROLE, payload: data1.role});
          dispatch({
            type: USERCREDIT_BY_CLIENTID,
            payload: data1.client_by_clientid?.usercredit_by_clientid,
          });
          dispatch({
            type: USERSUBSCRIPTION_BY_CLIENTID,
            payload: data1.client_by_clientid?.usersubscription_by_clientid,
          });
          dispatch({
            type: STRIPE_CUST_ID,
            payload: data1.client_by_clientid?.stripe_custid,
          });
          dispatch({type: LOGIN, payload: true});
          callback({success: 1, data: data1, action: 'login'});
        } else if (
          data.resource &&
          data.resource.length > 0 &&
          data.resource[0].clientdata
        ) {
          // console.log("signup", data);
          let userdata = data.resource[0]?.clientdata;
          dispatch({type: SESSION_TOKEN, payload: userdata});
          dispatch({type: AVATAR, payload: userdata.avatar});
          dispatch({type: CLIENT_ID, payload: userdata.clientid});
          dispatch({type: APPUSERID, payload: userdata.appuserid});
          dispatch({type: USER_EMAIL, payload: userdata.email});
          dispatch({type: IS_ACTIVE, payload: userdata.isactive});
          dispatch({type: IS_DELETED, payload: userdata.isdeleted});
          dispatch({type: IS_VERIFIED, payload: userdata.isverified});
          dispatch({type: USER_NAME, payload: userdata.name});
          dispatch({type: ROLE, payload: userdata.role});
          dispatch({type: LOGIN, payload: true});
          callback({success: 1, data: userdata, action: 'signup'});
        } else {
          callback({success: 0, data: data});
        }
      })
      .catch(error => {
        // callback({ success: 0, message: error });
      });
  };
}

export function sendVerificationemail(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/sendverificationemail`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        // callback({ success: 0, message: error });
      },
    );
  };
}
export function registrationConfirmationemail(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/registration_confirmation`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        // callback({ success: 0, message: error });
      },
    );
  };
}
export function sendPasswordemail(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/emailtonewuser`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        // callback({ success: 0, message: error });
      },
    );
  };
}

// export function getLinkedinemail(access_token, callback) {
//   // console.log("access_token", access_token);
//   const email = null;
//   const urlToGetUserEmail =
//     "https://api.linkedin.com/v2/clientAwareMemberHandles?q=members&projection=(elements*(primary,type,handle~))";
//   const config = {
//     headers: {
//       Authorization: `Bearer ${access_token}`,
//       "Cache-Control": "no-cache",
//       "Access-Control-Allow-Origin": "*",
//       mode: "no-cors",
//     },
//   };
//   axios
//     .get(urlToGetUserEmail, config)
//     .then((response) => {
//       email = response.data.elements[0]["handle~"];

//       callback({ success: 1, email: email });
//     })
//     .catch((error) => {
//       callback({ success: 0, error: error });
//     });
// }

export function getlinkedinuserEmail(session, values, callback) {
  var config = {
    headers: {
      contentType: 'application/json; charset=utf-8',
      'X-ApiFlo-Api-Key': APP_API_KEY,
    },
  };

  var url = `${INSTANCE_URL}/api/v2/linkedin_userdetails`;
  var response = axios.post(url, values, config);
  return dispatch => {
    response
      .then(({data}) => {
        // console.log("data123", data);
        callback({success: 1, data: data});
      })
      .catch(error => {
        // callback({ success: 0, message: error });
      });
  };
}
export function getlinkedinuserAccessToken(values, callback) {
  var config = {
    headers: {
      contentType: 'application/json; charset=utf-8',
      'X-ApiFlo-Api-Key': APP_API_KEY,
    },
  };

  var url = `${INSTANCE_URL}/api/v2/linkedin_accesstoken`;
  var response = axios.post(url, values, config);
  return dispatch => {
    response
      .then(({data}) => {
        // console.log("data123", data);
        callback({success: 1, data: data});
      })
      .catch(error => {
        callback({success: 0, message: error});
      });
  };
}

export function linkedinrefreshdata(data, callback) {
  //This function is used on Login screen only
  return dispatch => {
    if (data.resource && data.resource.length > 0 && data.resource[0].afid) {
      // console.log('login2',data);
      let data1 = data.resource[0];
      dispatch({type: SESSION_TOKEN, payload: data1});
      dispatch({type: AVATAR, payload: data1.avatar});
      dispatch({type: CLIENT_ID, payload: data1.clientid});
      dispatch({type: APPUSERID, payload: data1.appuserid});
      dispatch({type: USER_EMAIL, payload: data1.email});
      dispatch({type: IS_ACTIVE, payload: data1.isactive});
      dispatch({type: IS_DELETED, payload: data1.isdeleted});
      dispatch({type: IS_VERIFIED, payload: data1.isverified});
      dispatch({type: USER_NAME, payload: data1.name});
      dispatch({type: ROLE, payload: data1.role});
      dispatch({
        type: USERCREDIT_BY_CLIENTID,
        payload: data1.client_by_clientid?.usercredit_by_clientid,
      });
      dispatch({
        type: USERSUBSCRIPTION_BY_CLIENTID,
        payload: data1.client_by_clientid?.usersubscription_by_clientid,
      });
      dispatch({
        type: STRIPE_CUST_ID,
        payload: data1.client_by_clientid?.stripe_custid,
      });
      dispatch({type: LOGIN, payload: true});
      callback({success: 1, data: data1, action: 'login'});
    } else if (
      data.resource &&
      data.resource.length > 0 &&
      data.resource[0].clientdata
    ) {
      // console.log("signup", data);
      let userdata = data.resource[0]?.clientdata;
      dispatch({type: SESSION_TOKEN, payload: userdata});
      dispatch({type: AVATAR, payload: userdata.avatar});
      dispatch({type: CLIENT_ID, payload: userdata.clientid});
      dispatch({type: APPUSERID, payload: userdata.appuserid});
      dispatch({type: USER_EMAIL, payload: userdata.email});
      dispatch({type: IS_ACTIVE, payload: userdata.isactive});
      dispatch({type: IS_DELETED, payload: userdata.isdeleted});
      dispatch({type: IS_VERIFIED, payload: userdata.isverified});
      dispatch({type: USER_NAME, payload: userdata.name});
      dispatch({type: ROLE, payload: userdata.role});
      dispatch({type: LOGIN, payload: true});
      callback({success: 1, data: userdata, action: 'signup'});
    } else {
      callback({success: 0, data: data});
    }
  };
}

export function sendInvoice(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/sendinvoice`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}
